import React from 'react';
import MDSubtitleText from '../../components/ui/typography/MDSubtitleText';
import StripeErrorImage from '../../images/graphics/stripe-error-img.png';
import XSText from '../../components/ui/typography/XSText';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { Link } from 'gatsby';
import SEO from '../../components/seo';

export default function StripeFailure() {
  return (
    <>
      <SEO keywords={[`Statrys`]} noIndex={true} unFollow={true} />
      <div className="flex justify-center mt-10">
        <div className="bg-white rounded-lg shadow-lg md:w-5/12">
          <div className="flex flex-row justify-between p-8">
            <MDSubtitleText title={'Something went wrong'} fontWeight="text-bold" />
          </div>
          <div className="flex flex-col items-center mt-4 p-8">
            <img src={StripeErrorImage} className="w-1/2" />
            <XSText
              title={
                <div>
                  {
                    <div>
                      We were unable to process your payment. Please re-submit your application or{' '}
                      <Link to="/contact" state={{ fromStripeFailurePath: true }} className="hover:no-underline">
                        <span className="text-coral-500 cursor-pointer">contact us</span>
                      </Link>{' '}
                      for assistance.
                    </div>
                  }
                </div>
              }
              textAlign="text-center"
              className="mt-6 md:whitespace-pre-line md:px-14 px-8"
            />
          </div>
          <div className="flex flex-row gap-2 justify-between mt-6 md:px-12 px-4 pb-10 ">
            <PrimaryButton
              caption="Close"
              urlLink="/"
              bgColor="bg-white"
              color="text-black"
              className="button md:w-48 w-32"
            />
            <PrimaryButton
              caption="Retry"
              urlLink="/signup/start"
              bgColor="bg-coral-500"
              color="text-white"
              className="button md:w-48 w-32"
            />
          </div>
        </div>
      </div>
    </>
  );
}
